import { InventorySingle, Lot, LotFamilial, LotsType } from "~/types/inventory";

export const useGetPosLots = (
  payload: InventorySingle | InventorySingle[],
  lotsType: LotsType,
  storeId?: number
) => {
  if (!payload) return [];

  if (!Array.isArray(payload)) {
    // single inventory; familial lots
    if (
      storeId &&
      [LotsType.FAMILIAL_REGULAR, LotsType.FAMILIAL_UPCOMING].includes(lotsType)
    ) {
      return payload[lotsType]
        ?.filter((item) => (item as LotFamilial).store?.id === storeId)
        ?.reduce(
          (acc, item) =>
            acc.concat(
              (item as LotFamilial).groups.reduce(
                (acc, item) =>
                  acc.concat(
                    item.lots.map((lot) => ({
                      ...lot,
                      order_number: item.order?.number || item.order?.id,
                      order_pricing_method: item.order?.pricing_method,
                    }))
                  ),
                []
              )
            ),
          []
        );
    }

    // single inventory; REGULAR LOTS
    return payload[lotsType].reduce(
      (acc, item) =>
        acc.concat(
          (item as Lot).lots.map((lot) => ({
            ...lot,
            order_number:
              (item as Lot).order?.number || (item as Lot).order?.id,
            order_pricing_method: (item as Lot).order?.pricing_method,
          }))
        ),
      []
    );
  }

  if (
    storeId &&
    [LotsType.FAMILIAL_REGULAR, LotsType.FAMILIAL_UPCOMING].includes(lotsType)
  ) {
    // multiple inventories; familial lots
    return payload.reduce(
      (acc, curr) => [
        ...acc,
        ...curr[lotsType]
          ?.filter((item) => (item as LotFamilial).store?.id === storeId)
          ?.reduce(
            (acc, curr) => [
              ...acc,
              ...(curr as LotFamilial).groups.reduce(
                (acc, item) =>
                  acc.concat(
                    item.lots.map((lot) => ({
                      ...lot,
                      order_number: item.order?.number || item.order?.id,
                      order_pricing_method: item.order?.pricing_method,
                    }))
                  ),
                []
              ),
            ],
            []
          ),
      ],
      []
    );
  }

  // multiple inventories; REGULAR LOTS
  return payload.reduce(
    (acc, curr) => [
      ...acc,
      ...curr[lotsType].reduce(
        (acc, item) =>
          acc.concat(
            (item as Lot).lots.map((lot) => ({
              ...lot,
              order_number:
                (item as Lot).order?.number || (item as Lot).order?.id,
              order_pricing_method: (item as Lot).order?.pricing_method,
            }))
          ),
        []
      ),
    ],
    []
  );
};
