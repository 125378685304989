<template>
  <div>
    <!-- <div>
      <div class="pt-x3 flex flex-col gap-2.5">
        <div class="flex justify-between">
          <p class="text-font-primary text-sm leading-base font-medium">
            Subtotal:
          </p>
          <p
            v-if="
              posStore.cart.length || posStore.selectedAccountOrders?.length
            "
            class="text-font-primary text-sm"
          >
            {{
              useFormatPrice(
                posStore.isSameCustomerPayBalance
                  ? (+posStore.totals.subtotal_value || 0) +
                      posStore.subtotalSelectedAccountOrders
                  : posStore.selectedAccountOrders?.length
                    ? posStore.subtotalSelectedAccountOrders
                    : posStore.totals.subtotal_value
              )
            }}
          </p>
        </div> -->

    <!-- adjustments -->
    <!-- <template v-if="adjustments.length">
          <div
            v-for="fee in adjustments"
            :key="fee.name"
            class="flex justify-between"
          >
            <p class="text-font-primary font-medium text-sm">
              {{ fee.name }}:
            </p>
            <p class="text-font-primary text-sm">
              {{ useFormatPrice(fee.amount) }}
            </p>
          </div>
        </template>
      </div>

      <div class="pt-x3 flex flex-col gap-2.5">
        <div class="flex justify-between">
          <p class="text-font-primary text-sm leading-base font-medium">
            Processing Fee:
          </p>
          <p
            v-if="posStore.cart.length || posStore.selectedAccountOrders.length"
            class="text-font-primary text-sm"
          >
            {{
              useFormatPrice(
                getAdjustmentsOptionValue("Processing Fee")?.amount || 0
              )
            }}
          </p>
        </div>
      </div>
    </div> -->

    <!-- loyalty and rewards -->
    <!-- <div v-if="useConfig().isLoyaltyRewards" class="mt-x3 border-t">
      <div
        v-if="useRoute().name !== useRoutesNames().posShop"
        class="pt-x3 flex flex-col gap-2.5"
      >
        <div class="flex justify-between">
          <p class="text-font-primary text-sm leading-base font-medium">
            Paid with Points (Redeemed {{ posStore.redeemedPoints || 0 }}):
          </p>
          <p
            v-if="posStore.cart.length || posStore.selectedAccountOrders.length"
            class="text-font-primary text-sm"
          >
            {{ useFormatPrice(-posStore.totals.paid_with_points || 0) }}
          </p>
        </div>
      </div>
      <div class="pt-x3 flex flex-col gap-2.5">
        <div class="flex justify-between">
          <p class="text-font-primary text-sm leading-base font-medium">
            Volume Discount:
          </p>
          <p
            v-if="posStore.cart.length || posStore.selectedAccountOrders.length"
            class="text-font-primary text-sm"
          >
            {{
              useFormatPrice(
                getAdjustmentsOptionValue("Volume Discount")?.amount || 0
              )
            }}
          </p>
        </div>
      </div>
    </div> -->

    <div
      class="mt-[12px]"
      :class="[posStore.totals?.subtotal_value ? 'border-t' : '']"
    >
      <div class="pt-x3 flex flex-col gap-x3">
        <!-- subtotal -->
        <div
          v-if="posStore.totals?.subtotal_value"
          class="flex justify-between font-medium text-base"
        >
          <p>Subtotal:</p>
          <p>
            {{ useFormatPrice(posStore.totals.subtotal_value) }}
          </p>
        </div>

        <!-- order discount -->
        <div v-if="discount" class="flex justify-between font-medium text-base">
          <p>Discount:</p>
          <p>
            {{ useFormatPrice(discount.amount) }}
          </p>
        </div>

        <!-- stripe_fee_tax -->
        <div
          v-if="posStore.cart.length || posStore.selectedAccountOrders.length"
          class="flex justify-between font-medium text-base"
        >
          <p>Processing Fee:</p>
          <p>
            {{
              useFormatPrice(
                getAdjustmentsOptionValue("Volume Discount")?.amount || 0
              )
            }}
          </p>
        </div>
        <!-- totals -->
        <div class="flex justify-between font-medium text-base pt-1.5 border-t">
          <p class="text-lg font-semibold">Total:</p>
          <p
            v-if="posStore.cart.length || posStore.selectedAccountOrders.length"
          >
            {{ useFormatPrice(paymentStore.totalValue) }}
          </p>
        </div>
        <div
          class="flex justify-between font-medium -mt-4 text-xs border-b pb-2.5"
        >
          <p class="text text-muted-foreground">
            <!-- {{ posStore.totals.is_inclusive_tax ? "Tax included:" : "Tax Included:" }} -->
            Tax Included:
          </p>
          <p
            v-if="posStore.cart.length || posStore.selectedAccountOrders.length"
            class="text-muted-foreground"
          >
            {{
              useFormatPrice(
                posStore.isSameCustomerPayBalance
                  ? (+posStore.totals.tax_value || 0) +
                      posStore.taxSelectedAccountOrders
                  : posStore.selectedAccountOrders?.length
                    ? posStore.taxSelectedAccountOrders
                    : posStore.totals.tax_value
              )
            }}
          </p>
        </div>

        <!-- total savings -->
        <!-- <div v-if="useConfig().isPosHospitality" class="flex justify-between">
          <p class="text-font-primary font-medium text-sm flex items-center">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 mr-2.5"
            >
              <path
                d="M6 0C7.06452 0 8.08065 0.290323 9 0.822581C9.91935 1.35484 10.6452 2.08065 11.1774 3C11.7097 3.91935 12 4.93548 12 6C12 7.08871 11.7097 8.08065 11.1774 9C10.6452 9.91935 9.91935 10.6694 9 11.2016C8.08065 11.7339 7.06452 12 6 12C4.91129 12 3.91935 11.7339 3 11.2016C2.08065 10.6694 1.33065 9.91935 0.798387 9C0.266129 8.08065 0 7.08871 0 6C0 4.93548 0.266129 3.91935 0.798387 3C1.33065 2.08065 2.08065 1.35484 3 0.822581C3.91935 0.290323 4.91129 0 6 0ZM6 2.66129C5.70968 2.66129 5.46774 2.78226 5.27419 2.97581C5.08065 3.16935 4.98387 3.41129 4.98387 3.67742C4.98387 3.96774 5.08065 4.20968 5.27419 4.40323C5.46774 4.59677 5.70968 4.69355 6 4.69355C6.26613 4.69355 6.50806 4.59677 6.70161 4.40323C6.89516 4.20968 7.01613 3.96774 7.01613 3.67742C7.01613 3.41129 6.89516 3.16935 6.70161 2.97581C6.50806 2.78226 6.26613 2.66129 6 2.66129ZM7.35484 8.80645V8.22581C7.35484 8.15323 7.30645 8.08065 7.25806 8.03226C7.20968 7.98387 7.1371 7.93548 7.06452 7.93548H6.77419V5.51613C6.77419 5.44355 6.72581 5.37097 6.67742 5.32258C6.62903 5.27419 6.55645 5.22581 6.48387 5.22581H4.93548C4.83871 5.22581 4.76613 5.27419 4.71774 5.32258C4.66935 5.37097 4.64516 5.44355 4.64516 5.51613V6.09677C4.64516 6.19355 4.66935 6.26613 4.71774 6.31452C4.76613 6.3629 4.83871 6.3871 4.93548 6.3871H5.22581V7.93548H4.93548C4.83871 7.93548 4.76613 7.98387 4.71774 8.03226C4.66935 8.08065 4.64516 8.15323 4.64516 8.22581V8.80645C4.64516 8.90323 4.66935 8.97581 4.71774 9.02419C4.76613 9.07258 4.83871 9.09677 4.93548 9.09677H7.06452C7.1371 9.09677 7.20968 9.07258 7.25806 9.02419C7.30645 8.97581 7.35484 8.90323 7.35484 8.80645Z"
                :fill="useConfig().brandColor"
              />
            </svg>
            Total Savings:
          </p>
          <p
            v-if="posStore.cart.length || posStore.selectedAccountOrders.length"
            class="text-font-primary text-sm"
          >
            {{ useFormatPrice(+totalSavingsValue || 0) }}
          </p>
        </div> -->

        <!-- <div
              v-if="
                route.name === routes.posCheckout &&
                paymentStore.activePaymentMethod !== PaymentMethodGateway.ACCOUNT
              "
              class="flex justify-between"
            >
              <p class="text-sm leading-base font-semibold">Account Balance:</p>
              <p class="text-sm">
                ${{
                  (customersStore.activeCustomer as CustomerSingle).credit.due
                }}
              </p>
            </div> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { usePaymentStore } from "~/store/payment";
import { usePosStore } from "~/store/pos";

const posStore = usePosStore();
const paymentStore = usePaymentStore();

// discounts
const discount = computed(() =>
  posStore.totals?.adjustments?.find((adj) => adj.type === "discount")
);

// adjustments
function getAdjustmentsOptionValue(name) {
  return (
    posStore.totals?.adjustments?.find(
      (adjustment) => adjustment?.name === name
    ) || null
  );
}

// const filteredAdjustments = computed(
//   () =>
//     posStore.totals?.adjustments?.filter(
//       (adjustment) =>
//         adjustment.name !== "Volume Discount" &&
//         adjustment.name !== "Processing Fee"
//     ) || []
// );

// const adjustments = computed(() => {
//   const cartItemsDiscount = posStore.cart.reduce(
//     (prev, curr) => prev + (curr.discount_total ?? 0),
//     0
//   );

//   if (filteredAdjustments.value?.length) {
//     return filteredAdjustments.value.map((adj) =>
//       adj.type === "discount"
//         ? {
//             ...adj,
//             amount: adj.amount + cartItemsDiscount,
//           }
//         : adj
//     );
//   }

//   if (cartItemsDiscount) {
//     return [
//       {
//         name: "Discount",
//         type: "discount",
//         amount: cartItemsDiscount,
//       },
//     ];
//   }

//   return [];
// });

// total savings
const totalSavingsValue = computed(() => {
  const cartItemsDiscount = posStore.cart.reduce(
    (prev, curr) => prev + (curr.discount_total ?? 0),
    0
  );

  const orderDiscount = posStore.totals?.adjustments?.find(
    (i) => i.type === "discount"
  )?.amount;

  const firstOrderDiscount = posStore.totals?.adjustments?.find(
    (i) => i.type === "first-order-offer"
  )?.amount;

  return (
    (cartItemsDiscount || 0) +
    (orderDiscount || 0) +
    (firstOrderDiscount || 0) +
    (posStore.totals?.paid_with_points || 0)
  );
});
</script>
