<template>
  <div>
    <p class="font-medium">
      {{ cell.name }}
      <span
        v-if="symbols?.length"
        v-html="symbols"
        class="inline-flex gap-x1"
      ></span>
      <span v-if="subtitle">{{ subtitle }}</span>
    </p>
    <p v-if="addons" class="text-xs">{{ addons }}</p>
  </div>
</template>

<script setup lang="ts">
import type { OrderItem } from "~/types/orders";

const props = defineProps<{
  cell: OrderItem;
}>();

const addons = computed(() => {
  let addonsValues = (props.cell as OrderItem).addons
    ?.map((addon) => addon.value)
    .flat(Infinity);
  const addon = addonsValues
    ?.map((p) => useCapitalize(p.toString()))
    ?.join(", ");

  if (
    (props.cell as OrderItem).ext_title &&
    useConfig().isProductSecondaryName &&
    addon
  ) {
    return `${(props.cell as OrderItem).ext_title}, ${addon}`;
  } else if (
    (props.cell as OrderItem).ext_title &&
    useConfig().isProductSecondaryName
  ) {
    return (props.cell as OrderItem).ext_title;
  } else if (addon) {
    return addon;
  }
});

const symbols = computed(() => {
  const symbol = (props.cell as OrderItem).properties
    ?.filter((p) => p.symbol_html?.length)
    ?.map((prop) => prop.symbol_html)
    ?.join(" ");
  if (symbol) {
    return `${symbol}`;
  }
});

const subtitle = computed(() => {
  const variants = (props.cell as OrderItem).properties
    ?.filter((p) => !p.symbol_html?.length)
    ?.map((prop) => prop.title);

  // check using sequence matching and don't show variants if the product name already has it
  const filteredVariants = variants?.filter(
    (v) => !useCheckSequences((props.cell as OrderItem).name, v)
  );

  return filteredVariants?.length ? filteredVariants.join(", ") : null;
});
</script>
