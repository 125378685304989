<template>
  <div
    @click.stop="applyDiscount"
    class="flex justify-end items-center gap-x3 flex-1 text-right cursor-pointer"
  >
    <!-- <p :class="{ 'line-through': isDiscount }">
      {{
        useFormatPrice((item as OrderItem).price * (item as OrderItem).quantity)
      }}
    </p> -->
    <p>
      {{
        useFormatPrice((item as OrderItem).price * (item as OrderItem).quantity)
      }}
    </p>
    <!-- <p v-if="isDiscount">
      {{ useFormatPrice((item as OrderItem).price_total) }}
    </p> -->
  </div>
</template>

<script setup lang="ts">
import { usePosStore } from "~/store/pos";
import { OrderItem } from "~/types/orders";

const props = defineProps<{
  item: OrderItem;
}>();

const posStore = usePosStore();

const isDiscount = computed(
  () => props.item.discount && !props.item.is_sale_price
);

function applyDiscount() {
  posStore.discountOrderItemId = (props.item as OrderItem).id;

  posStore.isShowModalDiscountOrderItem = true;
}
</script>
