import { InventorySingle, LotFamilial, LotsType } from "~/types/inventory";

export const useGetPosLotsSuppliers = (
  payload: InventorySingle | InventorySingle[],
  lotsType: LotsType,
  storeId?: number
) => {
  const uniqueSupplierIds = new Set();

  const addUniqueSupplier = (acc, item) => {
    if (item.supplier && !uniqueSupplierIds.has(item.supplier.id)) {
      uniqueSupplierIds.add(item.supplier.id);

      // find the lot with the highest ID
      const highestIdLot = item.lots.reduce(
        (max, currentLot) => (max.id > currentLot.id ? max : currentLot),
        { id: -1 }
      );

      acc.push({
        ...item.supplier,
        stock: item.stock, // add stock property
        reserved_stock: item.reserved_stock, // add reserved_stock property
        lot_pricing_method: highestIdLot.pricing_method, // add pricing_method from the lot with the highest ID
        lot_sell_price: highestIdLot.sell_price, // add pricing_method from the lot with the highest ID
      });
    }
    return acc;
  };

  const addUniqueSupplierFamilial = (acc, item) => {
    item.groups.forEach((group) => {
      if (group.supplier && !uniqueSupplierIds.has(group.supplier.id)) {
        uniqueSupplierIds.add(group.supplier.id);

        // find the lot with the highest ID
        const highestIdLot = group.lots.reduce(
          (max, currentLot) => (max.id > currentLot.id ? max : currentLot),
          { id: -1 }
        );

        acc.push({
          ...group.supplier,
          stock: group.stock, // add stock property
          reserved_stock: group.reserved_stock, // add reserved_stock property
          lot_pricing_method: highestIdLot.pricing_method, // add pricing_method from the lot with the highest ID
          lot_sell_price: highestIdLot.sell_price, // add pricing_method from the lot with the highest ID
        });
      }

      return acc;
    });

    return acc;
  };

  if (!payload) return [];

  if (!Array.isArray(payload)) {
    // single inventory; familial lots
    if (
      storeId &&
      [LotsType.FAMILIAL_REGULAR, LotsType.FAMILIAL_UPCOMING].includes(lotsType)
    ) {
      return payload[lotsType]
        ?.filter((item) => (item as LotFamilial).store?.id === storeId)
        ?.reduce((acc, item) => addUniqueSupplierFamilial(acc, item), []);
    }

    // single inventory; REGULAR LOTS
    return payload[lotsType].reduce(
      (acc, item) => addUniqueSupplier(acc, item),
      []
    );
  }

  // multiple inventories; familial lots
  if (
    storeId &&
    [LotsType.FAMILIAL_REGULAR, LotsType.FAMILIAL_UPCOMING].includes(lotsType)
  ) {
    return payload.reduce(
      (acc, curr) => [
        ...acc,
        ...curr[lotsType]
          ?.filter((item) => (item as LotFamilial).store?.id === storeId)
          ?.reduce((acc, item) => addUniqueSupplierFamilial(acc, item), []),
      ],
      []
    );
  }

  // multiple inventories; REGULAR LOTS
  return payload.reduce(
    (acc, curr) => [
      ...acc,
      ...curr[lotsType].reduce((acc, item) => addUniqueSupplier(acc, item), []),
    ],
    []
  );
};
